import React, { useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import { Row, Col, Button, Card, Media, Table, Modal } from 'react-bootstrap'
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Animation from '../components/animate.js'
import Banner from '../components/banner'
import Common from '../components/common'
import Footer from '../components/footer'
import CommonFlex from '../components/commonFlex'
import Trade from '../components/trade'
import Hexagon from '../components/hexagon'
import Consult from '../components/consult'
import Markdown from "react-markdown"

import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'
import { useStaticQuery, graphql } from "gatsby"

import './../components/media.css'
import './../assets/css/index.css'
import './../assets/css/ecology.css'

// 引入此路径，才不会打包失败
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const data = [
  {
    title: '驱动全面化',
    url: require('./../images/base11.png'),
    img: require('./../images/base1.png'),
    href: '#driver',
  },
  {
    title: '低代码流程图',
    url: require('./../images/base22.png'),
    img: require('./../images/base2.png'),
    href: '#dashboard',
  },
  {
    title: '智能报表',
    url: require('./../images/base33.png'),
    img: require('./../images/base3.png'),
    href: '#report',
  },
  {
    title: '智能报警',
    url: require('./../images/base44.png'),
    img: require('./../images/base4.png'),
    href: '#warning',
  }
]


const engineData = [
  {
    title: '数据采集与控制引擎',
    desc: '海量工业设备驱动库，实现快速的设备接入、报警、自动化控制及远程控制，具备完整的SCADA功能。',
    url: require('./../images/engine1.png')
    // url: require('./../images/yinqin1.jpg')
  },
  {
    title: '可视化组态引擎',
    desc: '丰富可视化看板及组件选择，满足各类信息展示场景、工艺流程图、数据可视化场景。自定义行业展示，支持三维、SVG、图表，搭建各类专属页面，实现千人千面。',
    url: require('./../images/engine2.png')
  },
  {
    title: '数据分析引擎',
    desc: '灵活报表曲线，满足各类型数据分类及分析需求；毫秒级数据反馈速度， 快速响应客户分析条件变换查询需求。',
    url: require('./../images/engine3.png')
  },
  {
    title: '业务流引擎',
    desc: '创新强悍的流程引擎，为业务高效流转提供了坚实的技术支持，可视化的流程设计界面，无需代码即可轻松创建并管理业务流程、数据处理流程、事件报警流程等。',
    url: require('./../images/engine4.png')
  },
  {
    title: '二次开发引擎',
    desc: '提供丰富的前端、后台服务二次开发接口，具备灵活的组件服务部署与管理能力，几乎可以对任何功能模块进行二次开发，满足客户各类二次开发需求。',
    url: require('./../images/engine5.png')
  }
]

let scenarioCase = [
  {
    title: '智慧油田',
    bg_image: require('./../images/智慧油田.png') ,
    title_image: require('./../images/油田.png') 
  },
  {
    title: '智慧城市',
    bg_image: require('./../images/智慧城市.png') ,
    title_image: require('./../images/城市.png') 
  },
  {
    title: '车联网',
    bg_image: require('./../images/车联网.png') ,
    title_image: require('./../images/车联网l.png') 
  },

  {
    title: '数字油库',
    bg_image: require('./../images/数字油库.png') ,
    title_image: require('./../images/油库.png') 
  },
  {
    title: '智慧水厂',
    bg_image: require('./../images/智慧水厂.png') ,
    title_image: require('./../images/水厂.png') 
  },
  {
    title: '智慧工厂',
    bg_image: require('./../images/智慧工厂.png') ,
    title_image: require('./../images/工厂.png') 
  }
]

const IndexPage = () => {
  const [hash, setHash] = React.useState(null)
  const [agent, setAgent] = React.useState(true)
  const [recomments, setRecomments] = React.useState([])
  const [activeIndex, setActiveIndex] = React.useState(1)
  const [swipers, setSwipers] = React.useState(null)
  const [videoBg, setVideoBg] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const swiperRef = useRef()
  const videoRef = useRef()

  const [ initPerView, setPerView ] = React.useState(1)
  const [ isMobile, setIsMobile ] = React.useState(false)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
    if (typeof window !== "undefined") {
      if (window.location.hash && window.location.hash.indexOf('#') > -1) {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#') + 1))
      }

      window.addEventListener('resize', (event) => {
        let width = document.body.clientWidth
        if(width  < 768) {
          setIsMobile(true)
        }else {
          setIsMobile(false)
        }
      })
    }
    console.log(check(), 111112333)
    if(!check()) setIsMobile(true)
    setAgent(check())

  }, [])

  React.useEffect(() => {
    if (hash) {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('engineHead');
      window.scrollTo({
        top: product_media[0].offsetTop - 150
      })
    }
  }, [hash])

  const getCookie = (name) => {
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] == name) {
        return arr[1];
      }
    }
    return "";
  }

  const downPlat = () => {
    let cookieData = getCookie('data')
    if (cookieData && JSON.parse(cookieData)) {
      let contact_name = JSON.parse(cookieData).contact_name
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if")
    } else {
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if")
    }
  }

  const partner = [
    {
      title: '业务生态联盟',
      url: require('./../images/业务生态联盟.png'),
      hoverUrl: require('./../images/业务生态联盟.png'),
      href: '/ecologicalAlliance',
      desc: `<div class="card-text">赋能合作伙伴深耕行业，共享 项目资源，扩大商业合作机会。</div>`,
      target: '_self'
    },
    {
      title: '智慧物联商城',
      url: require('./../images/智慧物联商城.png'),
      hoverUrl: require('./../images/智慧物联商城.png'),
      href: '',
      desc: `<div class="card-text">聚合行业精英，提供优质项目 模板资源，培育商机。</div>`,
      class: 'reset-img',
      target: '_black'
    },
    {
      title: '工信部认证培训',
      url: require('./../images/能力补给站.png'),
      hoverUrl: require('./../images/能力补给站.png'),
      href: 'https://appxow65u356815.pc.xiaoe-tech.com/page/3231029',
      desc: `<div class="card-text">联合工信部人才交流中心开展 【AIRIOT物联网低代码平台应 用实战训练营】。</div>`,
      target: '_black'
    },
    {
      title: '行业专家智库',
      url: require('./../images/行业专家智库.png'),
      hoverUrl: require('./../images/行业专家智库.png'),
      href: '/industryAlliance',
      desc: '<div class="card-text">打造“专家资源库”，赋能AIRI OT金字塔服务体系，用心服务 更专业。</div>',
      target: '_self'
    }
  ]

  return (
    <div className="home-product-sp">
      <div className="home-index home-nav-fix">
        <Header />
      </div>
        <div className="home-bg home-index home-ecology home-ecology-top" style={{ position: 'relative', background: `url(${require("./../images/ecology.jpg")})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center 30%', height: '47.5rem' }}>
          <Layout typePage="home" >
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
            <div className="engine-plat">
              <div className="plat-jumbotron">
                  <div className="jumbotron-left">
                      <div className="jumbotron-title">链接 生态 共赢</div>
                      <div className="jumbotron-desc">联合面向行业上下游合作伙伴、客户、高校、协会打造智慧物联生态圈，共享业务生态联盟、智慧物联商城、物联网软件训练营认证培训等生态资源，充分发挥央企资源优势，共享工业物联网和AIoT领域的业务资产红利。</div>
                      <div className="jumbotron-btn-border">
                        <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if" target="_blank">
                          <Button variant="primary" className="engine-button">成为AIRIOT生态合作伙伴</Button>
                        </Link>
                      </div>
                  </div>
              </div>
              <div className="jumbotron-right" >
                  {/* <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img> */}
              </div>
            </div> 
          </Layout>
        </div>
      <Consult />
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <CommonFlex animate="animate1" title="为什么成为AIRIOT生态合作伙伴" desc=""></CommonFlex>
        <Row xs={1} md={2} lg={4} className="row-partner">
          {
              partner.map((item, index) => {
                  return (
                  <Col key={index}>
                      <Card className="common-card">
                          <Card.Img className={item.class} variant="top" src={item.url} alt={item.title} />
                      <Card.Body>
                          <Card.Title>{item.title}</Card.Title>
                          <div className="card-text">
                              <Markdown source={item.desc} escapeHtml={false} />
                          </div>
                          <Link style={ index == 1 ? { display: 'none' } : { display: 'block' } } to={item?.href} target={item?.target}><Button className="enter">点击进入</Button></Link>
                      </Card.Body>
                      </Card>
                  </Col>
                  )
              })
          }
        </Row>
      </Container>
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <CommonFlex animate="animate1" title="成为AIRIOT生态合作伙伴您将获得" desc=""></CommonFlex>
        <Row className="scenario-case">
          <Col><img className="auth-partner" src={require('./../images/合作伙伴授权.png')}/></Col>
        </Row>
        <CommonFlex animate="animate1" title="持续获得AIRIOT生态体系赋能" desc=""></CommonFlex>
        <Row className="scenario-case">
          <Col><img className="auth-partner" src={require('./../images/持续获得AIRIOT生态体系赋能.png')}/></Col>
        </Row>
        <CommonFlex animate="animate1" title="不断进化的物联生态圈" desc={"<a target='_black' href='https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if'><Button class='flex-btn'>成为AIRIOT生态合作伙伴</Button></a>"}></CommonFlex>
      
      </Container>
      <div className="ecology-swiper-box">
          <ul className="ecology-ul">
            <li className="ecology-li">
              <img src={require('./../images/001.png')}/>
              <img src={require('./../images/001.png')}/>
            </li>
            <li className="ecology-li">
              {/* <img src={require('./../images/002.png')}/>
              <img src={require('./../images/002.png')}/> */}
              <img src={require('./../images/WechatIMG254.png')}/>
              <img src={require('./../images/WechatIMG254.png')}/>
            </li>
            <li className="ecology-li">
              <img src={require('./../images/003.png')}/>
              <img src={require('./../images/003.png')}/>
            </li>
            <li className="ecology_cho_L"></li>
            <li className="ecology_cho_R"></li>
          </ul>
          {/* {
            isMobile ?  <Swiper
            modules={[ Navigation ]}
            slidesPerView={initPerView}
            navigation
            pagination={{ clickable: true }}
            onSwiper={(swiper) => {
              swiper.$el[0].addEventListener('mouseover', function() { swiper.autoplay.stop()});
              swiper.$el[0].addEventListener('mouseleave',()=>swiper.autoplay.start());
            }}
            onSlideChange={() => console.log('slide change')}
            className="ecology-swiper about-swiper"
            autoplay={{ delay: 2000 }}
            loop={true}
          >
            <SwiperSlide>
              <div>
                <Row xs={3}>
                  <Col><img src={require('./../images/1.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/2.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/3.jpg')} alt="" /></Col>
                </Row>
                <Row xs={3}>
                  <Col><img src={require('./../images/4.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/5.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/6.jpg')} alt="" /></Col>  
                </Row>
                <Row xs={3}>
                  <Col><img src={require('./../images/7.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/8.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/9.jpg')} alt="" /></Col>
                </Row>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Row>
                  <Col><img src={require('./../images/10.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/11.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/12.jpg')} alt="" /></Col>
                </Row> 
                <Row>
                  <Col><img src={require('./../images/13.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/14.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/15.jpg')} alt="" /></Col>
                </Row>
                <Row>
                  <Col><img src={require('./../images/16.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/17.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/18.jpg')} alt="" /></Col>
                </Row> 
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <Row>
                <Col><img src={require('./../images/19.jpg')} alt="" /></Col>
                <Col><img src={require('./../images/20.jpg')} alt="" /></Col>
                <Col><img src={require('./../images/21.jpg')} alt="" /></Col>
              </Row>
              <Row>
                <Col><img src={require('./../images/22.jpg')} alt="" /></Col>
                <Col><img src={require('./../images/23.jpg')} alt="" /></Col>
                <Col><img src={require('./../images/24.jpg')} alt="" /></Col>
              </Row>
              <Row>
                <Col><img src={require('./../images/25.jpg')} alt="" /></Col>  
                <Col><img src={require('./../images/26.jpg')} alt="" /></Col>
                <Col><img src={require('./../images/27.jpg')} alt="" /></Col>
              </Row>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Row>
                  <Col><img src={require('./../images/28.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/29.jpg')} alt="" /></Col>
                </Row> 
                <Row>
                  <Col><img src={require('./../images/30.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/31.jpg')} alt="" /></Col>
                </Row>
                <Row>
                  <Col><img src={require('./../images/32.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/33.jpg')} alt="" /></Col>
                </Row>
              </div>
            </SwiperSlide>
          </Swiper> :   <Swiper
            loop={true}
            modules={[ Navigation ]}
            slidesPerView={initPerView}
            navigation
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            className="ecology-swiper about-swiper"
            autoplay={{ delay: 2000 }}
            onSwiper={(swiper) => {
              swiper.$el[0].addEventListener('mouseover', function() { swiper.autoplay.stop()});
              swiper.$el[0].addEventListener('mouseleave',()=>swiper.autoplay.start());
            }}
          >
           
            <SwiperSlide>
              <div>
                <Row>
                  <Col><img src={require('./../images/1.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/2.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/3.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/4.jpg')} alt="" /></Col>
                </Row>
                <Row>
                  <Col><img src={require('./../images/5.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/6.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/7.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/8.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/9.jpg')} alt="" /></Col>
                </Row> 
                <Row>
                  <Col><img src={require('./../images/10.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/11.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/12.jpg')} alt="" /></Col>
                </Row>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Row>
                  <Col><img src={require('./../images/13.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/14.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/15.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/16.jpg')} alt="" /></Col>

                </Row>
                <Row>
                  <Col><img src={require('./../images/17.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/18.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/19.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/20.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/21.jpg')} alt="" /></Col>
                </Row> 
                <Row>
                  <Col><img src={require('./../images/22.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/23.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/24.jpg')} alt="" /></Col>
                </Row>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Row>
                  <Col><img src={require('./../images/25.jpg')} alt="" /></Col>  
                  <Col><img src={require('./../images/26.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/27.jpg')} alt="" /></Col>
                </Row>
                <Row>
                  <Col><img src={require('./../images/28.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/29.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/30.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/31.jpg')} alt="" /></Col>
                </Row> 
                <Row>
                  <Col><img src={require('./../images/32.jpg')} alt="" /></Col>
                  <Col><img src={require('./../images/33.jpg')} alt="" /></Col>
                </Row>
              </div>
            </SwiperSlide>
          </Swiper>
          } */}
        </div>
      <Footer style={{ marginTop: '8.5rem' }}></Footer>
    </div>

  )
}


export default IndexPage
